import React from "react"
import Box from "./box"
import Icon from "./icon"
import { answerStatus } from "../store/courses"

const SolutionButton = ({ children, status, ...restProps }) => {
  const styles = {
    [answerStatus.VALID_CHECKED]: {
      borderColor: "positive",
      bg: "positive",
    },
    [answerStatus.VALID_UNCHECKED]: {
      borderColor: "whisper",
      borderStyle: "dashed",
      color: "text",
      bg: "transparent",
    },
    [answerStatus.MISSED]: {
      borderColor: "caution",
      bg: "caution",
    },
    [answerStatus.INVALID]: {
      borderColor: "negative",
      bg: "negative",
    },
  }

  const icon = {
    [answerStatus.VALID_CHECKED]: "done",
    [answerStatus.VALID_UNCHECKED]: null,
    [answerStatus.MISSED]: null,
    [answerStatus.INVALID]: "close",
  }

  return (
    <Box
      sx={{
        borderWidth: 1,
        display: "inline-flex",
        borderRadius: "9999em",
        fontFamily: "sans",
        color: "background",
        p: 3,
        ...styles[status],
      }}
      {...restProps}
    >
      {status !== answerStatus.MISSED &&
        status !== answerStatus.VALID_UNCHECKED && (
          <Box mr={2}>
            <Icon icon={icon[status]} />
          </Box>
        )}
      {children}
    </Box>
  )
}

export default SolutionButton
